<template>
  <div v-if="false">
    <molecules-custom-dialog
      :show="showApproveBulk"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan approve semua absensi berdasarkan filter: ${
        ($store.state.attendance.data &&
          $store.state.attendance.data.filter((x) => x.locationId && x.absensiCompleted).length) ||
        0
      } Pekerja (Hari Ini)!`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApproveBulk = false),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            approveBulk()
            showApproveBulk = false
          },
        },
      ]"
    />
    <molecules-custom-dialog
      v-if="showApprove"
      :show="showApprove"
      :title="showApprove.supervisorApproval == $status.approve ? 'Approve Pekerja' : 'Reject Pekerja' + '?'"
      :caption="`Anda akan meng-${showApprove.supervisorApproval == $status.approve ? 'approve' : 'reject'} pekerja (${
        showApprove.worker && showApprove.worker.fullName
      })`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApprove = null),
        },
        {
          title: showApprove.supervisorApproval == $status.approve ? 'Approve' : 'Reject',
          override: 'red--text',
          full: false,
          exec: () => {
            toggleApproval()
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Komentar Anda" :noLabel="true" v-model="comment"
    /></molecules-custom-dialog>
    <!-- summary -->
    <v-row>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.attendance.total} Pekerja Aktif`"
          :desc="`${$moment($store.state.attendance.from || new Date()).format('DD MMMM YYYY')} - ${$moment(
            $store.state.attendance.till || new Date(),
          ).format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-details"
          :title="`${$store.state.attendance.length} 
                  Absensi`"
          :cap="`Terhitung pada ${day === 'Semua' ? 'Semua Hari' : `hari ${day}`}`"
          :desc="$moment().format('DD MMMM YYYY')"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-check"
          :title="`${$store.state.attendance.complete} 
                  Hadir`"
          :cap="`Terhitung pada ${day === 'Semua' ? 'Semua Hari' : `hari ${day}`}`"
          :desc="$moment().format('DD MMMM YYYY')"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-check"
          :title="`${$store.state.attendance.approved}
                  Approved`"
          :cap="`Terhitung pada ${day === 'Semua' ? 'Semua Hari' : `hari ${day}`}`"
          :desc="$moment().format('DD MMMM YYYY')"
          color="green"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>
    <!-- filter -->
    <v-row align="center" justify="space-between">
      <v-col cols="12" md="10">
        <atoms-text-field
          label="Ketikkan nama atau nik pekerja..."
          v-model.trim="term"
          :noLabel="true"
          :appendAction="
            () => {
              term = ''
              loadData()
            }
          "
          appendIcon="mdi-close"
        />
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-space-between">
        <atoms-button @click="loadData" style="width: 100%" class="primary lighten-5 primary--text"
          ><v-icon left>mdi-magnify</v-icon>Cari Absensi</atoms-button
        >
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="4">
            <atoms-text-field label="Area">
              <v-autocomplete
                class="rounded-lg"
                :items="getAreaItems"
                v-model="getAreaName"
                @change="getAttendance"
                :menu-props="{ offsetY: true }"
                hide-details
                solo
                dense
                flat
                required
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              />
            </atoms-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <atoms-text-field label="Lokasi">
              <v-autocomplete
                class="rounded-lg"
                :items="getAreaName === $status.semua ? [$status.semua] : getLocItems"
                v-model="getLocName"
                @change="getAttendance"
                :menu-props="{ offsetY: true }"
                hide-details
                solo
                flat
                dense
                required
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              />
            </atoms-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <atoms-text-field label="Status">
              <v-combobox
                class="rounded-lg"
                placeholder="Filters"
                prepend-inner-icon="mdi-filter-variant"
                v-model="filter"
                :items="filters.map((x) => x.title)"
                @change="loadData"
                hide-details
                multiple
                dense
                solo
                flat
                required
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              />
            </atoms-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col align-self="end" cols="12">
        <v-row justify="center" align="center">
          <v-col cols="12" md="auto" class="grow d-flex">
            <atoms-text class="font-weight-medium"
              ><v-icon left>mdi-account-group</v-icon> Absensi {{ $moment().format('dddd, DD MMMM YYYY') }}</atoms-text
            >
            <v-icon
              @click="
                () => {
                  $showDialog({
                    title: 'Informasi Absensi',
                    body: 'Approval Absensi hanya bisa dilakukan jika sudah melalukan filtering dengan memilih Area dan Lokasi',
                  })
                }
              "
              right
              style="cursor: pointer"
              >mdi-information</v-icon
            >
          </v-col>
          <v-col cols="12" md="auto" class="shrink">
            <atoms-button
              :disabled="
                (areas.value && areas.value.title === $status.semua) ||
                (locations.value && locations.value.title === $status.semua) ||
                !$store.state.attendance.data
              "
              @click="showApproveBulk = true"
              style="width: 100%"
              class="primary lighten-5 primary--text"
              ><v-icon left>mdi-check</v-icon> Approve Semua Absensi</atoms-button
            >
          </v-col>
          <!-- v-if="$store.state.member.roles.includes($roles.Admin)" -->
          <v-col cols="12" md="auto" class="shrink">
            <atoms-button style="width: 100%" class="green lighten-4 green--text" @click="exportXlsx"
              ><v-icon left>mdi-google-spreadsheet</v-icon> Export Xlsx</atoms-button
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- content -->
    <v-fade-transition>
      <div v-if="mainLoading" class="mt-4">
        <v-skeleton-loader
          v-for="i in $store.state.attendance.limit || 5"
          :key="i"
          type="list-item-avatar-three-line"
          class="accent rounded-lg mb-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.attendance.data && $store.state.attendance.data.length > 0" class="mt-4">
          <div
            v-for="(
              { _id, ktpPhl, absensiCompleted, supervisorApproval, location, phlJamMulai, area, worker, ...rest }, i
            ) in $store.state.attendance.data.filter((x) => x.locationId && !x.worker.blacklist) || []"
            :key="i"
            :class="['accent', 'rounded-lg mb-2 overflow-hidden']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <div class="pa-4 mb-2">
              <v-row align="center">
                <v-col class="shrink">
                  <v-avatar
                    :color="`${supervisorApproval ? 'primary' : absensiCompleted ? 'green' : 'red'} lighten-4`"
                    class="rounded-lg"
                  >
                    <atoms-image
                      v-if="worker && worker.photo"
                      class="rounded-lg"
                      :enableZoom="true"
                      :src="worker.photo"
                    />
                    <v-icon v-else :color="supervisorApproval ? 'primary' : absensiCompleted ? 'green' : 'red'"
                      >mdi-account</v-icon
                    ></v-avatar
                  >
                </v-col>
                <v-col cols="8" md="4">
                  <atoms-text class="font-weight-bold d-flex align-center"
                    >{{ $toCapitalize(worker.fullName || 'Lokasi') }}
                    <v-chip
                      v-if="
                        worker.blacklist ||
                        worker.resigned ||
                        (worker.clientSuspends && worker.clientSuspends.find((x) => x._id === $store.state.client._id))
                      "
                      small
                      class="rounded-lg red lighten-5 red--text ml-2"
                      >{{
                        (worker.blacklist && 'Blacklisted') ||
                        (worker.resigned && 'Resign') ||
                        (worker.clientSuspends &&
                          worker.clientSuspends.find((x) => x._id === $store.state.client._id) &&
                          'Suspend')
                      }}</v-chip
                    ></atoms-text
                  >
                  <atoms-text class="primary--text"
                    >Area {{ (area && area.title) || '-' }}, Lokasi {{ (location && location.name) || '-' }}</atoms-text
                  >
                </v-col>
                <v-col align="start" cols="12" md="auto">
                  <atoms-text class="font-weight-bold"
                    >Status: {{ supervisorApproval ? supervisorApproval : 'Proses' }}</atoms-text
                  >
                  <atoms-text class="primary--text"
                    >Absensi <span v-if="rest.isManual">Manual</span> :
                    {{ absensiCompleted ? 'Lengkap' : 'Belum Lengkap' }}</atoms-text
                  >
                </v-col>
                <v-col :align="$vuetify.breakpoint.smAndDown ? 'start' : 'end'">
                  <atoms-text>Pukul: {{ $moment(phlJamMulai).format('H:mm:ss, DD MMMM YYYY') }}</atoms-text>
                  <atoms-text>{{ $idCurrency((rest.shift && rest.shift.priceBase) || 0) }}</atoms-text>
                </v-col>
                <v-col class="shrink">
                  <v-menu offset-x class="rounded-xl">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <v-list nav dense elevation="0" class="text-center rounded-lg">
                      <v-list-item
                        link
                        @click="
                          showApprove = {
                            _id,
                            ktpPhl,
                            absensiCompleted,
                            supervisorApproval: $status.approve,
                            location,
                            phlJamMulai,
                            area,
                            worker,
                            ...rest,
                          }
                        "
                        class="rounded-lg px-4 text-center"
                      >
                        <v-icon>mdi-account-check</v-icon>
                        <v-list-item-title class="px-2">Approve</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="
                          showApprove = {
                            _id,
                            ktpPhl,
                            absensiCompleted,
                            supervisorApproval: $status.reject,
                            location,
                            phlJamMulai,
                            area,
                            worker,
                            ...rest,
                          }
                        "
                        class="rounded-lg px-4 text-center"
                      >
                        <v-icon>mdi-account-cancel</v-icon>
                        <v-list-item-title class="px-2">Reject Absensi</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :to="`/attendance/riwayat?search=${ktpPhl}`"
                        class="rounded-lg px-4 text-center"
                      >
                        <v-icon left>mdi-account-details</v-icon>
                        <v-list-item-title class="px-2">Cek Riwayat</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        class="rounded-lg px-4 text-center"
                        @click="
                          showDetail = {
                            _id,
                            ktpPhl,
                            absensiCompleted,
                            supervisorApproval,
                            location,
                            phlJamMulai,
                            area,
                            worker,
                            ...rest,
                          }
                        "
                      >
                        <v-icon left>mdi-information</v-icon>
                        <v-list-item-title class="px-2">Lihat Detail</v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item
                      link
                      disabled
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-account-cancel</v-icon>
                      <v-list-item-title class="px-2"
                        >Blacklist</v-list-item-title
                      >
                    </v-list-item> -->
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <molecules-wrapper class="px-0">
                <atoms-text class="font-weight-bold">{{ (rest.client && rest.client.name) || 'Client' }}</atoms-text>
                <atoms-text>{{ (rest.shift && rest.shift.title) || '-' }}</atoms-text>
              </molecules-wrapper>
            </div>
            <div v-if="rest.isOutsideLocation && rest.geofenceDistance" :class="[' white--text pa-2 px-4 orange']">
              <atoms-text>
                Absensi dilakukan diluar lokasi gedung, Jarak absensi sejauh
                {{ rest.geofenceDistance || 0 }}m dari gudang
              </atoms-text>
            </div>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <molecules-wrapper class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.attendance.pagesLength"></v-pagination>
              </molecules-wrapper>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Data Yang Anda Cari Tidak Ditemukan</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </v-fade-transition>
    <v-slide-x-reverse-transition>
      <organism-attendance-detail
        v-if="showDetail"
        @closed="
          () => {
            reset()
            showDetail = null
          }
        "
        @toggleApprove="(e) => (showApprove = e)"
        :data="showDetail"
      />
    </v-slide-x-reverse-transition>
    <!-- <organism-absensi-approve
      v-if="approve"
      :show="approve"
      :setApprove="(value) => (approve = value)"
      :data="data"
      :closeAllDialogAndReset="closeAllDialogAndReset"
      @closed="closeAllDialogAndReset"
    /> -->
    <!-- <molecules-helper-attendance-day
      v-if="showHelper"
      @closed="showHelper = false"
      :show="showHelper"
    /> -->
  </div>
  <div v-else>
    <atoms-text
      >Akses Halaman di
      <a href="https://hayosystems-v3.web.app/attendance">https://hayosystems-v3.web.app/attendance</a></atoms-text
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      day: this.$moment().format('dddd'),
      showApproveBulk: false,
      showHelper: true,
      showDetail: null,
      showApprove: null,
      comment: 'OK',
      areas: {
        items: null,
        value: null,
      },
      locations: {
        items: null,
        value: null,
      },
      filter: null,
      filters: [
        {
          title: 'Diluar Geofence',
          value: {
            isOutsideLocation: true,
            geofenceDistance: { $ne: null },
          },
        },
        {
          title: 'Absensi Manual',
          value: {
            isManual: true,
          },
        },
        // {
        //   title: this.$status.proses,
        //   value: {
        //     // absensiCompleted: true,
        //     $or: [{ supervisorApproval: { $exists: false } }, { supervisorApproval: '' }],
        //   },
        // },
        {
          title: this.$status.belum,
          value: {
            // absensiCompleted: false,
            $or: [{ supervisorApproval: { $exists: false } }, { supervisorApproval: '' }],
          },
        },
        {
          title: this.$status.approve,
          value: {
            absensiCompleted: true,
            supervisorApproval: this.$status.approve,
          },
        },
        {
          title: this.$status.reject,
          value: {
            absensiCompleted: true,
            supervisorApproval: this.$status.reject,
          },
        },
        {
          title: this.$status.semua,
          value: {
            absensiCompleted: undefined,
            supervisorApproval: undefined,
          },
        },
      ],
      term: '',
      page: 1,
      mainLoading: true,
      reportLoading: true,
    }
  },
  computed: {
    getAttend() {
      return this.$store.state.attendance.data
    },
    getAreaItems() {
      return this.areas.items ? [this.$status.semua, ...this.areas.items.map((x) => x.title)] : []
    },
    getAreaName: {
      set(value) {
        if (value === this.$status.semua) {
          this.areas.value = {
            ...this.areas.items[0],
            title: this.$status.semua,
          }
          this.locations.items = this.areas.items.flatMap((x) => x.locations)
        } else {
          this.areas.value = this.areas.items ? this.areas.items.find((x) => x.title === value) : null
          this.locations.items = this.areas.value ? this.areas.value.locations : null
        }
        this.getLocName = this.$status.semua
      },
      get() {
        return this.areas.value ? this.areas.value.title : ''
      },
    },
    getLocItems() {
      return this.locations.items ? [this.$status.semua, ...this.locations.items.map((x) => x.name)] : []
    },
    getLocName: {
      set(value) {
        if (value === this.$status.semua) {
          this.locations.value = {
            ...this.areas.items[0],
            title: this.$status.semua,
          }
        } else {
          this.locations.value = this.locations.items ? this.locations.items.find((e) => e.name === value) : null
        }
      },
      get() {
        return this.locations.value ? this.locations.value.title || this.locations.value.name : ''
      },
    },
  },
  watch: {
    comment() {
      if (!this.comment) {
        this.comment = 'OK'
      }
    },
    page: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  mounted() {
    return
    this.reset()
  },
  methods: {
    async reset() {
      this.$store.commit('SET', { loading: true })
      await this.$store.dispatch('area/getAll', { limit: 0 })
      this.areas.items = this.$store.state.area?.data
      this.getAreaName = this.$status.semua
      this.$store.commit('SET', { loading: false })
      this.page = 1
      this.filter = [this.$status.semua]
      this.term = ''
      this.comment = 'OK'
      this.loadData()
    },
    async loadData() {
      this.getSummary()
      this.getAttendance()
    },
    async approveBulk() {
      const arrAbsensi = this.$store.state.attendance?.data
        .filter((x) => x?.locationId && x?.absensiCompleted)
        .map((item) => {
          return {
            _id: item._id,
            supervisorId: this.$store.state.user?._id,
            supervisorName: this.$store.state.user?.nickname,
            supervisorApproval: 'Approved',
            supervisorComment: 'OK - Approval',
            supervisorTimeStamp: new Date(),
          }
        })
      if (arrAbsensi.length > 0) {
        await this.$store.dispatch('attendance/approveBulk', arrAbsensi)
        this.loadData()
      } else {
        this.$showDialog({
          title: 'Kesalahan',
          body: 'Tidak ada pekerja yang selesai absensi!',
        })
      }
    },
    async toggleApproval() {
      this.$store.commit('SET', { loading: true })
      try {
        const data = {
          ...this.showApprove,
          supervisorId: this.$store.state.user?._id,
          supervisorName: this.$store.state.user?.nickname,
          supervisorComment: this.comment
            ? this.comment
            : 'OK - ' + this.showApprove?.supervisorApproval == this.$status.approve
            ? 'Approve'
            : 'Reject',
          supervisorTimeStamp: new Date(),
          worker: undefined,
          detail: undefined,
          client: undefined,
        }
        await this.$store
          .dispatch('attendance/setStatus', data)
          .then(() => {
            this.$store.commit('dialog/show', {
              title: 'Berhasil',
              body: `Pekerja atas nama ${this.$toCapitalize(this.showApprove?.worker?.fullName || '-')} berhasil ${
                (this.showApprove?.supervisorApproval !== this.$status.approve && 'reject') || ''
              } approval`,
            })
          })
          .catch(() => {
            this.$store.commit('dialog/show', {
              title: 'Gagal',
              body: `Pekerja atas nama ${this.$toCapitalize(this.showApprove?.worker?.fullName || '-')} gagal ${
                (this.showApprove?.supervisorApproval !== this.$status.approve && 'reject') || ''
              } approval`,
            })
          })
      } finally {
        this.showDetail = null
        this.showApprove = null
        this.comment = ''
        this.loadData()
        this.$store.commit('SET', { loading: false })
      }
    },
    async getSummary() {
      this.reportLoading = true
      await this.$store.dispatch('attendance/getSummary', {
        query: this.queries(),
      })
      this.reportLoading = false
    },
    getFilter() {
      const term = { $regex: this.term || '', $options: 'i' }
      return {
        ...this.queries(),
        ...(this.filter && this.filter.length > 0
          ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
          : []),
        pipeline: [
          { $sort: { _createdDate: -1 } },
          {
            $lookup: {
              from: 'QuotationItems',
              localField: 'shiftId',
              foreignField: '_id',
              as: 'shift',
            },
          },
          {
            $unwind: {
              path: '$shift',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Clients',
              localField: 'clientId',
              foreignField: '_id',
              as: 'client',
            },
          },
          {
            $unwind: {
              path: '$client',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Workers',
              localField: 'ktpPhl',
              foreignField: '_id',
              as: 'worker',
            },
          },
          {
            $unwind: {
              path: '$worker',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $unwind: {
              path: '$detail',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Areas',
              localField: 'areaId',
              foreignField: '_id',
              as: 'area',
            },
          },
          {
            $unwind: {
              path: '$area',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $match: {
              $or: [{ 'worker.fullName': term }, { 'worker.email': term }],
            },
          },
        ],
      }
    },
    async getAttendance() {
      console.log(this.getFilter())
      this.mainLoading = true
      await this.$store.dispatch('attendance/getAll', {
        custom: this.getFilter(),
        page: this.page,
      })
      this.mainLoading = false
    },
    async exportXlsx() {
      this.$store.commit('SET', { loading: true })
      const jsonQuery = this.getFilter()
      // handle xlsx
      const realm = await this.$realm()
      const attendances = await realm.functions.getAttendances(JSON.stringify(jsonQuery))
      let columns = [
        {
          label: 'No KTP',
          value: 'No KTP',
        },
        {
          label: 'Nama Pekerja',
          value: 'Nama Pekerja',
        },
        {
          label: 'Perusahaan',
          value: 'Perusahaan',
        },
        {
          label: 'No Telepon',
          value: 'No Telepon',
        },
        {
          label: 'Kapten',
          value: 'Kapten',
        },
        {
          label: 'Area',
          value: 'Area',
        },
        {
          label: 'Gudang/Lokasi',
          value: 'Gudang/Lokasi',
        },
        {
          label: 'Hari',
          value: 'Hari',
        },
        {
          label: 'Tanggal Mulai',
          value: 'Tanggal Mulai',
        },
        {
          label: 'Jam Mulai',
          value: 'Jam Mulai',
        },
        {
          label: 'Tanggal Selesai',
          value: 'Tanggal Selesai',
        },
        {
          label: 'Jam Selesai',
          value: 'Jam Selesai',
        },
        {
          label: 'Lokasi Mulai',
          value: 'Lokasi Mulai',
        },
        {
          label: 'Lokasi Selesai',
          value: 'Lokasi Selesai',
        },
        {
          label: 'Note Mulai',
          value: 'Note Mulai',
        },
        {
          label: 'Note Selesai',
          value: 'Note Selesai',
        },
        {
          label: 'Supervisor Name',
          value: 'Supervisor Name',
        },
        {
          label: 'Supervisor Approval',
          value: 'Supervisor Approval',
        },
        {
          label: 'Jenis Rekening',
          value: 'Jenis Rekening',
        },
        {
          label: 'Nama Rekening',
          value: 'Nama Rekening',
        },
        {
          label: 'Nomor Rekening',
          value: 'Nomor Rekening',
        },
        {
          label: 'Sudah Bayar Admin',
          value: 'Sudah Bayar Admin',
        },
        {
          label: 'Referral',
          value: 'Referral',
        },
        {
          label: 'Absensi Manual',
          value: 'Absensi Manual',
        },
      ]
      
      // hide some fields for certain roles
      if(this.$isRoles([this.$roles.Area_Coordinator])) {
        let valuesToRemove = ['No KTP', 'Jenis Rekening', 'Nama Rekening', 'Nomor Rekening'];
        columns = columns.filter(column => !valuesToRemove.includes(column.value));
      }

      const content = attendances
        .sort((a, b) => b.phlJamMulai - a.phlJamMulai)
        .map((item) => {
          const mapData = {
            'No KTP': item['No KTP'],
            'Nama Pekerja': item['Nama Pekerja'],
            Perusahaan: item['Perusahaan'],
            "No Telepon": item['No Telepon'],
            Kapten: item['Kapten'],
            Area: item['Area'],
            'Gudang/Lokasi': item['Gudang/Lokasi'],
            Hari: item['Hari'],
            'Tanggal Mulai': item['Tanggal Mulai'],
            'Jam Mulai': item['Jam Mulai'],
            'Tanggal Selesai': item['Tanggal Selesai'],
            'Jam Selesai': item['Jam Selesai'],
            'Lokasi Mulai': item['Lokasi Mulai'],
            'Lokasi Selesai': item['Lokasi Selesai'],
            'Note Mulai': item['Note Mulai'],
            'Note Selesai': item['Note Selesai'],
            'Supervisor Name': item['Supervisor Name'],
            'Supervisor Approval': item['Supervisor Approval'],
            'Jenis Rekening': item['Jenis Rekening'],
            'Nama Rekening': item['Nama Rekening'],
            'Nomor Rekening': item['Nomor Rekening'],
            'Sudah Bayar Admin': item['Sudah Bayar Admin'],
            Referral: item['Referral'],
            'Absensi Manual': item['Absensi Manual'],
          }
          // hide some fields for certain roles
          if(this.$isRoles([this.$roles.Area_Coordinator])) {
            delete mapData['No KTP'];
            delete mapData['Jenis Rekening'];
            delete mapData['Nama Rekening'];
            delete mapData['Nomor Rekening'];
          }
          return mapData;
        })
      let data = [
        {
          sheet: 'Absensi',
          columns,
          content,
        },
      ]
      let settings = {
        fileName: 'Absensi',
      }
      this.$xlsx(data, settings)
      this.$store.commit('SET', { loading: false })
    },
    queries() {
      const today = this.$moment()
      return {
        phlJamMulai: {
          $gte: new Date(today.toDate().setHours(0, 0, 0, 0)),
          $lte: new Date(today.toDate().setHours(24, 0, 0, 0)),
        },
        areaId:
          this.areas.value && this.areas.value.title === this.$status.semua
            ? { $in: this.areas.items.map((x) => x._id) }
            : this.areas.value?._id,
        locationId:
          this.locations.value && this.locations.value.title === this.$status.semua
            ? { $exists: true }
            : this.locations.value?._id,
        // ...(this.filter && this.filter.length > 0
        //   ? Object.assign(
        //       {},
        //       ...this.filter?.map(
        //         (x) => this.filters?.find((y) => y.title === x)?.value
        //       )
        //     )
        //   : []),
      }
    },
  },
}
</script>
