var render = function render(){var _vm=this,_c=_vm._self._c;return (false)?_c('div',[_c('molecules-custom-dialog',{attrs:{"show":_vm.showApproveBulk,"title":"Apakah Anda Yakin?","caption":`Anda akan approve semua absensi berdasarkan filter: ${
      (_vm.$store.state.attendance.data &&
        _vm.$store.state.attendance.data.filter((x) => x.locationId && x.absensiCompleted).length) ||
      0
    } Pekerja (Hari Ini)!`,"items":[
      {
        title: 'Batalkan',
        full: false,
        exec: () => (_vm.showApproveBulk = false),
      },
      {
        title: 'Ya, Lanjutkan',
        override: 'red--text',
        full: false,
        exec: () => {
          _vm.approveBulk()
          _vm.showApproveBulk = false
        },
      },
    ]}}),(_vm.showApprove)?_c('molecules-custom-dialog',{attrs:{"show":_vm.showApprove,"title":_vm.showApprove.supervisorApproval == _vm.$status.approve ? 'Approve Pekerja' : 'Reject Pekerja' + '?',"caption":`Anda akan meng-${_vm.showApprove.supervisorApproval == _vm.$status.approve ? 'approve' : 'reject'} pekerja (${
      _vm.showApprove.worker && _vm.showApprove.worker.fullName
    })`,"items":[
      {
        title: 'Batalkan',
        full: false,
        exec: () => (_vm.showApprove = null),
      },
      {
        title: _vm.showApprove.supervisorApproval == _vm.$status.approve ? 'Approve' : 'Reject',
        override: 'red--text',
        full: false,
        exec: () => {
          _vm.toggleApproval()
        },
      },
    ]}},[_c('atoms-text-field',{attrs:{"color":"background","label":"Komentar Anda","noLabel":true},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('molecules-summary-card',{attrs:{"icon":"account-multiple","title":`${_vm.$store.state.attendance.total} Pekerja Aktif`,"desc":`${_vm.$moment(_vm.$store.state.attendance.from || new Date()).format('DD MMMM YYYY')} - ${_vm.$moment(
          _vm.$store.state.attendance.till || new Date(),
        ).format('DD MMMM YYYY')}`,"loading":_vm.reportLoading}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('molecules-summary-card',{attrs:{"icon":"account-details","title":`${_vm.$store.state.attendance.length} 
                Absensi`,"cap":`Terhitung pada ${_vm.day === 'Semua' ? 'Semua Hari' : `hari ${_vm.day}`}`,"desc":_vm.$moment().format('DD MMMM YYYY'),"loading":_vm.reportLoading}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('molecules-summary-card',{attrs:{"icon":"account-check","title":`${_vm.$store.state.attendance.complete} 
                Hadir`,"cap":`Terhitung pada ${_vm.day === 'Semua' ? 'Semua Hari' : `hari ${_vm.day}`}`,"desc":_vm.$moment().format('DD MMMM YYYY'),"loading":_vm.reportLoading}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('molecules-summary-card',{attrs:{"icon":"account-check","title":`${_vm.$store.state.attendance.approved}
                Approved`,"cap":`Terhitung pada ${_vm.day === 'Semua' ? 'Semua Hari' : `hari ${_vm.day}`}`,"desc":_vm.$moment().format('DD MMMM YYYY'),"color":"green","loading":_vm.reportLoading}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('atoms-text-field',{attrs:{"label":"Ketikkan nama atau nik pekerja...","noLabel":true,"appendAction":() => {
            _vm.term = ''
            _vm.loadData()
          },"appendIcon":"mdi-close"},model:{value:(_vm.term),callback:function ($$v) {_vm.term=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"term"}})],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","md":"2"}},[_c('atoms-button',{staticClass:"primary lighten-5 primary--text",staticStyle:{"width":"100%"},on:{"click":_vm.loadData}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v("Cari Absensi")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('atoms-text-field',{attrs:{"label":"Area"}},[_c('v-autocomplete',{staticClass:"rounded-lg",style:({
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }),attrs:{"items":_vm.getAreaItems,"menu-props":{ offsetY: true },"hide-details":"","solo":"","dense":"","flat":"","required":""},on:{"change":_vm.getAttendance},model:{value:(_vm.getAreaName),callback:function ($$v) {_vm.getAreaName=$$v},expression:"getAreaName"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('atoms-text-field',{attrs:{"label":"Lokasi"}},[_c('v-autocomplete',{staticClass:"rounded-lg",style:({
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }),attrs:{"items":_vm.getAreaName === _vm.$status.semua ? [_vm.$status.semua] : _vm.getLocItems,"menu-props":{ offsetY: true },"hide-details":"","solo":"","flat":"","dense":"","required":""},on:{"change":_vm.getAttendance},model:{value:(_vm.getLocName),callback:function ($$v) {_vm.getLocName=$$v},expression:"getLocName"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('atoms-text-field',{attrs:{"label":"Status"}},[_c('v-combobox',{staticClass:"rounded-lg",style:({
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }),attrs:{"placeholder":"Filters","prepend-inner-icon":"mdi-filter-variant","items":_vm.filters.map((x) => x.title),"hide-details":"","multiple":"","dense":"","solo":"","flat":"","required":""},on:{"change":_vm.loadData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1)],1),_c('v-col',{attrs:{"align-self":"end","cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"grow d-flex",attrs:{"cols":"12","md":"auto"}},[_c('atoms-text',{staticClass:"font-weight-medium"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(" Absensi "+_vm._s(_vm.$moment().format('dddd, DD MMMM YYYY')))],1),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"right":""},on:{"click":() => {
                _vm.$showDialog({
                  title: 'Informasi Absensi',
                  body: 'Approval Absensi hanya bisa dilakukan jika sudah melalukan filtering dengan memilih Area dan Lokasi',
                })
              }}},[_vm._v("mdi-information")])],1),_c('v-col',{staticClass:"shrink",attrs:{"cols":"12","md":"auto"}},[_c('atoms-button',{staticClass:"primary lighten-5 primary--text",staticStyle:{"width":"100%"},attrs:{"disabled":(_vm.areas.value && _vm.areas.value.title === _vm.$status.semua) ||
              (_vm.locations.value && _vm.locations.value.title === _vm.$status.semua) ||
              !_vm.$store.state.attendance.data},on:{"click":function($event){_vm.showApproveBulk = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Approve Semua Absensi")],1)],1),_c('v-col',{staticClass:"shrink",attrs:{"cols":"12","md":"auto"}},[_c('atoms-button',{staticClass:"green lighten-4 green--text",staticStyle:{"width":"100%"},on:{"click":_vm.exportXlsx}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-google-spreadsheet")]),_vm._v(" Export Xlsx")],1)],1)],1)],1)],1),_c('v-fade-transition',[(_vm.mainLoading)?_c('div',{staticClass:"mt-4"},_vm._l((_vm.$store.state.attendance.limit || 5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"accent rounded-lg mb-2",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"type":"list-item-avatar-three-line"}})}),1):_c('div',[(_vm.$store.state.attendance.data && _vm.$store.state.attendance.data.length > 0)?_c('div',{staticClass:"mt-4"},[_vm._l((_vm.$store.state.attendance.data.filter((x) => x.locationId && !x.worker.blacklist) || []),function({ _id, ktpPhl, absensiCompleted, supervisorApproval, location, phlJamMulai, area, worker, ...rest },i){return _c('div',{key:i,class:['accent', 'rounded-lg mb-2 overflow-hidden'],style:({
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          })},[_c('div',{staticClass:"pa-4 mb-2"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"color":`${supervisorApproval ? 'primary' : absensiCompleted ? 'green' : 'red'} lighten-4`}},[(worker && worker.photo)?_c('atoms-image',{staticClass:"rounded-lg",attrs:{"enableZoom":true,"src":worker.photo}}):_c('v-icon',{attrs:{"color":supervisorApproval ? 'primary' : absensiCompleted ? 'green' : 'red'}},[_vm._v("mdi-account")])],1)],1),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('atoms-text',{staticClass:"font-weight-bold d-flex align-center"},[_vm._v(_vm._s(_vm.$toCapitalize(worker.fullName || 'Lokasi'))+" "),(
                      worker.blacklist ||
                      worker.resigned ||
                      (worker.clientSuspends && worker.clientSuspends.find((x) => x._id === _vm.$store.state.client._id))
                    )?_c('v-chip',{staticClass:"rounded-lg red lighten-5 red--text ml-2",attrs:{"small":""}},[_vm._v(_vm._s((worker.blacklist && 'Blacklisted') || (worker.resigned && 'Resign') || (worker.clientSuspends && worker.clientSuspends.find((x) => x._id === _vm.$store.state.client._id) && 'Suspend')))]):_vm._e()],1),_c('atoms-text',{staticClass:"primary--text"},[_vm._v("Area "+_vm._s((area && area.title) || '-')+", Lokasi "+_vm._s((location && location.name) || '-'))])],1),_c('v-col',{attrs:{"align":"start","cols":"12","md":"auto"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v("Status: "+_vm._s(supervisorApproval ? supervisorApproval : 'Proses'))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v("Absensi "),(rest.isManual)?_c('span',[_vm._v("Manual")]):_vm._e(),_vm._v(" : "+_vm._s(absensiCompleted ? 'Lengkap' : 'Belum Lengkap'))])],1),_c('v-col',{attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'start' : 'end'}},[_c('atoms-text',[_vm._v("Pukul: "+_vm._s(_vm.$moment(phlJamMulai).format('H:mm:ss, DD MMMM YYYY')))]),_c('atoms-text',[_vm._v(_vm._s(_vm.$idCurrency((rest.shift && rest.shift.priceBase) || 0)))])],1),_c('v-col',{staticClass:"shrink"},[_c('v-menu',{staticClass:"rounded-xl",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-center rounded-lg",attrs:{"nav":"","dense":"","elevation":"0"}},[_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":function($event){_vm.showApprove = {
                          _id,
                          ktpPhl,
                          absensiCompleted,
                          supervisorApproval: _vm.$status.approve,
                          location,
                          phlJamMulai,
                          area,
                          worker,
                          ...rest,
                        }}}},[_c('v-icon',[_vm._v("mdi-account-check")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Approve")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":function($event){_vm.showApprove = {
                          _id,
                          ktpPhl,
                          absensiCompleted,
                          supervisorApproval: _vm.$status.reject,
                          location,
                          phlJamMulai,
                          area,
                          worker,
                          ...rest,
                        }}}},[_c('v-icon',[_vm._v("mdi-account-cancel")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Reject Absensi")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":"","to":`/attendance/riwayat?search=${ktpPhl}`}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-details")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Cek Riwayat")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":function($event){_vm.showDetail = {
                          _id,
                          ktpPhl,
                          absensiCompleted,
                          supervisorApproval,
                          location,
                          phlJamMulai,
                          area,
                          worker,
                          ...rest,
                        }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Lihat Detail")])],1)],1)],1)],1)],1),_c('molecules-wrapper',{staticClass:"px-0"},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s((rest.client && rest.client.name) || 'Client'))]),_c('atoms-text',[_vm._v(_vm._s((rest.shift && rest.shift.title) || '-'))])],1)],1),(rest.isOutsideLocation && rest.geofenceDistance)?_c('div',{class:[' white--text pa-2 px-4 orange']},[_c('atoms-text',[_vm._v(" Absensi dilakukan diluar lokasi gedung, Jarak absensi sejauh "+_vm._s(rest.geofenceDistance || 0)+"m dari gudang ")])],1):_vm._e()])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('molecules-wrapper',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.$store.state.attendance.pagesLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2):_c('molecules-wrapper',{staticClass:"d-flex justify-center align-center my-8 py-8 outlined"},[_c('div',{staticClass:"text-center"},[_c('atoms-image',{attrs:{"width":250,"src":"/assets/missing.svg"}}),_c('atoms-title',{staticClass:"mt-6",attrs:{"h3":true}},[_vm._v("Data Yang Anda Cari Tidak Ditemukan")])],1)])],1)]),_c('v-slide-x-reverse-transition',[(_vm.showDetail)?_c('organism-attendance-detail',{attrs:{"data":_vm.showDetail},on:{"closed":() => {
          _vm.reset()
          _vm.showDetail = null
        },"toggleApprove":(e) => (_vm.showApprove = e)}}):_vm._e()],1)],1):_c('div',[_c('atoms-text',[_vm._v("Akses Halaman di "),_c('a',{attrs:{"href":"https://hayosystems-v3.web.app/attendance"}},[_vm._v("https://hayosystems-v3.web.app/attendance")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }